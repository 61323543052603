<template>
    <div class="card card-f-h flex flex-col">
        <ConfirmationModal @success="getAll" />

        <div class="flex items-end justify-between border-b border-gray-400 mb-3 pb-3">
            <el-dropdown trigger="click">
                <el-button type="primary">
                    {{ $t('employees.menu') }} <i class="el-icon-arrow-down el-icon--right" />
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <router-link :to="{ name: 'employees_archived' }">
                        <el-dropdown-item>
                            {{ $t('employees.archived') }}
                        </el-dropdown-item>
                    </router-link>
                </el-dropdown-menu>
            </el-dropdown>
            <div class="flex">
                <ManagersDropdown :inView="'employees_all'" :cookieChoice="true" :customClass="'mr-2'" @selected="getAll" />
                <el-input v-model="quickSearch" suffix-icon="el-icon-search" class="w-72" :placeholder="$t('common.search')" clearable />
            </div>
        </div>

        <EmployeesAllTable
            :data="employeesData"
            @handleArchive="handleArchive"
        />

        <InitialLoader v-if="$wait.is('loading.initial_*')" />
    </div>
</template>
<script>
import queryString from 'query-string';

export default {
    components: {
        ManagersDropdown:  () => import(/* webpackChunkName: "ManagersDropdown" */ '@/components/ManagersDropdown'),
        EmployeesAllTable: () => import(/* webpackChunkName: "EmployeesAllTable" */ './components/EmployeesAllTable'),
    },

    data() {
        return {
            quickSearch:         '',
            employeesData:       [],
            employeesDataOrigin: [],
        };
    },

    watch: {
        quickSearch() {
            this.filter();
        },
    },

    beforeCreate() {
        this.$wait.start('loading.initial_employees');
    },

    created() {
        this.$store.commit('setPageTitle', `${this.$t('employees.all_employees')} (${this.employeesDataOrigin.length})`);
        this.$store.commit('setActiveMenuItem', '3-1');

        this.getAll();
    },

    methods: {
        async getAll() {
            axios.cancel('justRequest');
            const { manager } = queryString.parse(window.location.search, { sort: false });

            this.quickSearch = '';
            this.$wait.start('loading');

            const employees = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees`, {
                requestId: 'justRequest',
                params:    {
                    manager,
                },
            });

            this.employeesData = Object.freeze(employees.data);
            this.employeesDataOrigin = Object.freeze(employees.data);
            this.$store.commit('setPageTitle', `${this.$t('employees.all_employees')} (${this.employeesDataOrigin.length})`);

            this.$wait.end('loading');
            this.$wait.end('loading.initial_employees');
        },

        handleArchive(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl: `${process.env.VUE_APP_CORE_API_URL}/employees/employees/${uuid}`,
            });
        },

        filter() {
            const search = this.quickSearch.toLowerCase();
            this.employeesData = this.employeesDataOrigin.filter(employee => (
                String(employee.id).includes(search)
                    || employee.name.toLowerCase().includes(search)
                    || employee.manager.toLowerCase().includes(search)
                    || (employee.surname && employee.surname.toLowerCase().includes(search))
            ));
        },
    },
};
</script>
